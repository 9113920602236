<template>
  <!-- 调整客户归属 -->
  <div class="customer_box">
    <div class="customer_search_box">
      <div class="search-item">
        <span>关键字</span>
        <el-input style="width: 176px;" placeholder="请输入审批单号" size="small" v-model="filterinfo.search"></el-input>
      </div>
      <div class="search-item">
        <span>单据状态</span>
        <el-select style="width: 160px;" size="small" v-model="filterinfo.shopType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="btn_box">
        <el-button class="btn_item" type="primary" size="small" @click="getCustomerList">查询</el-button>
        <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
        <el-button style="font-size: 14px;" type="primary" plain size="small" @click="addClick"><i class="el-icon-plus" style="font-weight: 600;margin-right: 2px"></i>发起调整客户归属</el-button>
      </div>
    </div>
    <div class="table_mode">
      <el-table :data="tableData" v-loading="loading" @sort-change="handleSortChange" stripe style="width: 100%">
        <el-table-column prop="companyName" label="审批单号" min-width="120" :show-overflow-tooltip="true" align="center" fixed="left"></el-table-column>
        <el-table-column prop="businessDepart" label="状态" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="memberLevel" label="创建时间" min-width="150" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="address" label="操作 " width="130" align="center">
          <template slot-scope="scope">
            <div class="table_btn">
              <div class="btn_item" @click="detailClick">详情</div>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="nodata_box">
          <img src="@/assets/images/index/nodata.png"/>
          <span class="txt">暂无数据哦~</span>
        </div>
      </el-table>
    </div>
    <div class="pagination_box">
      <el-pagination
        background
        layout="prev, pager, next, sizes, total"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="filterinfo.pageSize"
        :current-page.sync="filterinfo.page"
        :total="total"
        @current-change="getCustomerList"
        @size-change="getCustomerList(1)">
      </el-pagination>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomerAttribution',
  data() {
    return {
      loading: false,
      total: 0,
      filterinfo:{
        page:1,
        pageSize:10,
        search: '',
        orderBy: ''
      },
      tableData:[],
      options: [],
    };
  },
  created() {

  },
  mounted(){
    this.getCustomerList()
  },
  methods: {
    // 点击重置
    resetClick(){
      this.filterinfo = {
        page:1,
        pageSize:10,
        search: '',
        orderBy: ''
      }
      this.getCustomerList()
    },
    // 获取客户账龄列表
    getCustomerList(num){
      if(num==1){
        this.filterinfo.page = 1
      }
      let params = this.filterinfo
      this.loading = true
      this.$api.pcBillList(params).then((res) => {
        this.loading = false
        if (res.data.code === 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
        }
      });
    },
    handleSortChange({ column, prop, order }) {
      if(order == 'ascending'){
        this.filterinfo.orderBy = prop + " asc"
      }else{
        this.filterinfo.orderBy = prop + " desc"
      }
      this.getCustomerList()
    },
    // 发起调整客户归属
    addClick(){
      this.$router.push('/launchAttribution')
    },
    // 调整客户归属详情
    detailClick(){
      this.$router.push('/detailAttribution')
    },
  }
};
</script>
<style lang="scss" scoped>
.customer_box{
  position: relative;
}
</style>